import Builds from '../components/builds_homepage/Builds';
import Button from 'react-bootstrap/Button';
import { FaArrowUp } from 'react-icons/fa';
import ScrollToTop from 'react-scroll-to-top';

const HomePage = () => {
  return (
    <>
      <ScrollToTop
        id="scroll-to-top"
        title="back to top"
        smooth
        top={750}
        component={<FaArrowUp id="arrow" size={'1.5em'} />}
      />

      <div className="page-content">
        <section className=" company-intro">
          <div className="container-sm sec-contents">
            <div className="examp-img ">
              <img
                src={require('./imgs/FirstBornServices-logos_black.png')}
                className="fbs-logo"
                height="225px"
                alt=""
              />
            </div>
            <div className="about-intro">
              <p>Quality Products.</p>
              <p>Excellent Service.</p>
            </div>
          </div>
        </section>
        <section className="container-fluid company-services">
          <p className="about-intro">We offer a variety of services.</p>
          <div className="container-fluid">
            <div className="row row-cols-1 row-cols-sm-4 g-3">
              <div className="col">
                <div className="card h-100">
                  <img
                    src={require('./imgs/help-desk.jpg')}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">IT Support</h5>
                    <p className="card-text">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img
                    src={require('./imgs/net-sec.jpg')}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Cybersecurity</h5>
                    <p className="card-text">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img
                    src={require('./imgs/builds.jpg')}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Custom Builds</h5>
                    <p className="card-text">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img
                    src={require('./imgs/installs.jpg')}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Computer Repair</h5>
                    <p className="card-text">
                      This is a longer card with supporting text below as a
                      natural lead-in to additional content. This content is a
                      little bit longer.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Builds />
        <section className="container-fluid company-webdev">
          <p className="about-intro">
            Responsive Website? FBS will get you sorted.
          </p>
        </section>
        <section className="container-fluid company-outro">
          <div className="container cust-srvc">
            <p className="about-intro">Best Services at the Best Rates!</p>
            <br></br>
            <br></br>
            <img
              src={require('./imgs/bestsrvc.jpg')}
              className="happy-cust"
              alt="..."
            />
            <br></br>
            <br></br>
            <Button variant="dark" className="lrn-more-btn">
              Learn More
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};
export default HomePage;
