import { FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      {/* line */}
      <div className="w-full h-px bg-[#495057] mt-0 mb-0"></div>
      {/* footer */}
      <div className="px-5 py-7 max-w-[1560px] mx-auto footy">
        {/* top */}
        <div className="flex justify-between gap-y-10 flex-wrap">
          {/* left */}
          <div className="mx-auto md:mx-0">
            <div className=" flex items-center gap-8 mb-3 ">
              {/* logo */}
              <div className=" flex gap-2 items-center text-2xl text-white font-bold">
                <img
                  src={require('./img/lion-logopng-33401.png')}
                  alt=""
                  className="footy-logo"
                />
                <span>First Born Services</span>
              </div>
              {/* email */}
              <span className="text-[#ABB2BF]"></span>
            </div>
            {/* disc */}
            <div className="text-[#ffffff]">
              <p></p>
            </div>
            <p style={{ lineHeight: 1 }}>
              <br />
              <br />
              <br />
            </p>
          </div>
          {/* right */}
          <div className=" mx-auto md:mx-0">
            {/* title */}
            <h2 className=" text-white text-2xl font-medium mb-3">Socials</h2>
            {/* media */}
            <div className="flex items-center gap-2">
              <a
                title="LinkedIn"
                href="https://www.linkedin.com/in/chidi-okpara/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin color="#ABB2BF" />
              </a>
              <a
                title="X"
                href="https://twitter.com/FBSchidoskii"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter color="#ABB2BF" />
              </a>
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className="text-center text-[#ABB2BF] pb-2 mt-3">
          © Copyright 2024
        </div>
      </div>
    </>
  );
};

export default Footer;
