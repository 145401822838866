import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import first from '../builds_homepage/imgs/gamerig.jpg';
import second from '../builds_homepage/imgs/performance-based.jpg';
import third from '../builds_homepage/imgs/workstat.jpg';
import fourth from '../builds_homepage/imgs/Monitor-Setup.jpg';

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const GridExample = () => {
  const buildTypes = [
    {
      img: first,
      title: 'Gaming Rigs',
      desc: 'Its a gaming pc',
    },
    {
      img: second,
      title: 'Workstations',
      desc: 'High performance computers',
    },
    {
      img: third,
      title: 'Light Models',
      desc: 'Refurbished PCs',
    },
    {
      img: fourth,
      title: 'Monitor and PC Setup',
      desc: 'Customized interior designs.',
    },
  ];
  return (
    <Container>
      <Row xs={1} md={4} className="banerows g-2">
        <>
          <Col>
            <Card className="h-100">
              <Card.Img variant="top" src={first} />
              <Card.Body>
                <Card.Title>{buildTypes[0].title}</Card.Title>
                <Card.Text>{buildTypes[0].desc}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Img variant="top" src={second} />
              <Card.Body>
                <Card.Title>{buildTypes[1].title}</Card.Title>
                <Card.Text>{buildTypes[1].desc}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Img variant="top" src={third} />
              <Card.Body>
                <Card.Title>{buildTypes[2].title}</Card.Title>
                <Card.Text>{buildTypes[2].desc}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className="h-100">
              <Card.Img variant="top" src={fourth} />
              <Card.Body>
                <Card.Title>{buildTypes[3].title}</Card.Title>
                <Card.Text>{buildTypes[3].desc}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </>
      </Row>
    </Container>
  );
};

export default GridExample;
