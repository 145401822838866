import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/Navbar.jsx';
import HomePage from './pages/HomePage.jsx';
import Footer from './components/footer/Footer.jsx';
import { IconContext } from 'react-icons/lib';
import { useEffect, useMemo } from 'react';

function App() {
  const iconSize = useMemo(() => ({ size: '2em' }), []);

  return (
    <IconContext.Provider value={iconSize}>
      <div className="bg-[#111]">
        <Navbar />
        <HomePage />
        <Footer />
      </div>
    </IconContext.Provider>
  );
}

export default App;
