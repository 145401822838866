import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import './style.css';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div className="fixed top-0 left-0 right-0 bg-[#111] navi">
        <div className="px-5 max-w-[1560px] mx-auto flex items-center justify-between my-1">
          <div className="left flex gap-2 items-center font-bold text-white text-base">
            {/*logo*/}
            <div className=" img">
              <img
                className="navlogo"
                src={require('./imgs/logo.png')}
                alt=""
              />
            </div>
          </div>
          <div className="right flex items-center">
            <div
              className={`menu duration-300 flex flex-col md:flex-row justify-center fixed w-full ${
                !toggle
                  ? `right-[-100%] top-0 bottom-0`
                  : `right-0 top-0 bottom-0`
              } bg-[#111] md:static`}
            >
              <a href="#">
                <li className="text-[#fff] text-[32px] my-4 md:my-auto md:text-base list-none mx-4">
                  <span className="text-[#CC9933] font-medium">{'>'}</span> Home
                </li>
              </a>
              <a href="#">
                <li className="text-[#fff] text-[32px] my-4 md:my-auto md:text-base list-none mx-4">
                  <span className="text-[#CC9933] font-medium">{'>'}</span>{' '}
                  Services
                </li>
              </a>
              <a href="#">
                <li className="text-[#fff] text-[32px] my-4 md:my-auto md:text-base list-none mx-4">
                  <span className="text-[#CC9933] font-medium">{'>'}</span>{' '}
                  Contact
                </li>
              </a>
              <div
                onClick={() => setToggle(false)}
                className="close absolute block md:hidden right-3 top-3"
              >
                <img src={require('./imgs/close.png')} alt="" />
              </div>
            </div>
            {/* Close & Open buttons */}
            <div onClick={() => setToggle(true)} className="">
              <div className="open block md:hidden w-8">
                <div className="w-full h-[1px] my-2 rounded-r-3xl bg-[#D9D9D9]"></div>
                <div className="w-full h-[1px] my-2 rounded-r-3xl bg-[#D9D9D9]"></div>
                <div className="w-full h-[1px] my-2 rounded-r-3xl bg-[#D9D9D9]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
