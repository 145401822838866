import GridExample from '../services_featured/featuredServices.jsx';

const Builds = () => {
  return (
    <>
      <section className="container-fluid company-builds">
        <div className="bp-container">
          <p className="about-intro">Custom Builds? FBS got you.</p>
          <div className="container build-items">
            <GridExample />
          </div>
        </div>
      </section>
    </>
  );
};

export default Builds;
